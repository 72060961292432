<template>
  <div class="survey">
    <!-- Content Title -->
    <section class="content-title">
      <h1>문진하기</h1>
    </section>

    <!-- Section -->
    <section class="survey-form-wrapper">
      <!-- Survey Header -->
      <div class="survey-header">
        <div class="survey-title d-flex justify-content-between">
          <h1>[ {{ ptInfo.ptNm }} ] 문진 </h1>
          <p>
            <span class="mr-4" v-if="ptWeight">표준체중 : {{ ptWeight }}kg</span>
          </p>
        </div>
        <div class="survey-tab-wrapper">
          <div class="survey-tab" v-for="(tab, i) in survTabs" :key="i" @click="clickTab(ptInfo.ptDiagType, i)" :disabled="i > ptSurvs.all.length">
            <div class="inline-btn" :class="{ active: curTab === i, disabled: i > ptSurvs.all.length }">{{ tab }}</div>
          </div>
        </div>
      </div>

      <!-- Survey Form -->
      <div class="survey-form-wrapper">
        <b-row class="fix-row">
          <b-col class="fix-col fix-label col-3">
            등록번호
          </b-col>
          <b-col class="fix-col col-9">
            {{ ptInfo.ptDiagCode }}   
          </b-col>
        </b-row>
        <b-row class="fix-row" v-if="curTab === 0"> <!-- 1차 문진에서만 인적사항 정보를 불러옴, 인적사항 문항을 사용하지 않음 -->
          <b-col class="fix-col fix-label col-3">
            인적사항
          </b-col>
          <b-col class="fix-col col-9 p-0">
            <b-row>
              <b-col><span>출생 연도</span> {{ptInfo.ptBirth}}</b-col>
              <b-col><span>성별</span> {{ptInfo.ptGender === 'M' ? '남' : '여'}}</b-col>
            </b-row>
          </b-col>
        </b-row>
        <div v-for="(qDiag, i) in qDiags" :key="i">
          <div v-if="ptInfo.ptDiagType === qDiag.ptDiagType">
            <div v-for="qSurvType in qDiag.qSurvTypes" :key="qSurvType.survType">
              <div v-if="survType === qSurvType.survType">
                <component
                  v-for="(qData, i) in result[qSurvType.resultNo].qData"
                  :key="qData.qNo"
                  :is="getDynamicComponent(qData.qNo)"
                  v-model="result[qSurvType.resultNo].qData[i]"
                ></component>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Survey Footer -->
      <div class="survey-footer d-flex justify-content-end">
        <b-button class="survey-save-btn" size="lg" type="button" @click="save(survType)">저장</b-button>
      </div>
    </section>
  </div>
</template>
<script>
function createSurveyForm() {
  var qData = [];
  qData.push(
    {
      qNo: 1,
      title: '등록방법 및 등록일',
      answer: [
        { label: '등록방법', data: '' },
        { label: '등록일', data: '' },
      ],
    },
    {
      qNo: 2,
      title: '인적사항',
      answer: [
        { label: '출생 연도', data: '' },
        { label: '성별', data: '' },
      ],
    },
    {
      qNo: 3,
      title: '신체 계측',
      answer: [
        { label: '체중', data: '' },
        { label: '키', data: '' },
      ],
    },
    {
      qNo: 4,
      title: '신체 계측 (체중)',
      answer: [
        { data: '' },
      ],
    },
    {
      qNo: 5,
      title: '의료기관 방문 여부',
      answer: [{ data: '' }, { label: '날짜', data: '' }, { label: '이유', data: '' }],
    },
    {
      qNo: 6,
      title: '경구 당 부하 검사',
      answer: [{ data: '' }, { label: ['검사일', '검사 병원', '검사 결과 공복혈당'], data: ['', '', ''] }, { label: '이유', data: '' }],
    },
    {
      qNo: 7,
      title: '공복혈당 검사 여부',
      answer: [{ data: '' }, { label: ['검사일', '검사 결과'], data: ['', ''] }, { label: '이유', data: '' }],
    },
    {
      qNo: 8,
      title: '당뇨병 진단일',
      answer: [{ data: '' }],
    },
    {
      qNo: 9,
      title: '공복혈당 검사 결과 및 날짜',
      answer: [
        { label: '결과', data: '' },
        { label: '공복혈당 검사일', data: '' },
      ],
    },
    {
      qNo: 10,
      title: '당화혈색소 검사',
      answer: [{ data: '' }, { label: ['날짜', '결과'], data: ['', ''] }, { label: '이유', data: '' }],
    },
    {
      qNo: 11,
      title: '당뇨약제 처방',
      answer: [{ data: '' }, { label: '약명', data: '' }, { label: '이유', data: '' }],
    },
    {
      qNo: 12,
      title: '고혈당 인지여부',
      answer: [{ data: '' }, { label: '언제', data: '' }, { label: '이유', data: '' }],
    },
    {
      qNo: 13,
      title: '공복혈당 재검 여부',
      answer: [{ data: '' }, { label: ['날짜', '검진 병원', '결과'], data: ['', '', ''] }, { label: '이유', data: '' }],
    },
    {
      qNo: 14,
      title: '정기적 방문 중인 의료기관',
      answer: [
        { data: '' },
        { label: '병원', data: '' },
        { label: ['이유', '거주지 근처 의료기관 안내', '의료기관 방문 의사'], data: ['', '', ''] },
      ],
    },
    {
      qNo: 15,
      title: '당뇨병 치료약제',
      answer: [
        { data: '' },
        { label: '경구약제 이름', data: '' },
        { label: '인슐린 이름', data: '' },
        { label: '복용 투약', data: '' },
        { label: '이유', data: '' },
        { label: '한 달 기준 빠지는 횟수', data: '' },
      ],
    },
    {
      qNo: 16,
      title: '고혈압',
      answer: [{ data: '' }, { label: '고혈압 약명', data: '' }],
    },
    {
      qNo: 17,
      title: '고지혈증',
      answer: [{ data: '' }, { label: '고지혈증 약명', data: '' }],
    },
    {
      qNo: 18,
      title: '가족력 (당뇨병)',
      answer: [{ data: '' }, { label: '관계', data: [] }],
    },
    {
      qNo: 19,
      title: '직업',
      answer: [{ data: '' }, { label: '그 외', data: '' }],
    },
    {
      qNo: 20,
      title: '교대근무',
      answer: [{ data: '' }],
    },
    {
      qNo: 21,
      title: '가구 형태',
      answer: [{ data: '' }],
    },
    {
      qNo: 22,
      title: '음주',
      answer: [{ data: '' }, { label: ['빈도', '술 종류', '술 량'], data: ['', '', ''] }],
    },
    {
      qNo: 23,
      title: '흡연',
      answer: [{ data: '' }, { label: ['하루 개비 수', '첫 흡연 나이'], data: ['', ''] }, { label: '금연기간', data: '' }],
    },
    {
      qNo: 24,
      title: '흡연',
      answer: [{ data: '' }, { label: '하루 개비 수', data: '' }, { label: '금연기간', data: '' }],
    },
    {
      qNo: 25,
      title: '수면 시간 (24시간 기준)',
      answer: [
        { label: '잠자는 시간', data: '' },
        { label: '일어나는 시간', data: '' },
      ],
    },
    {
      qNo: 26,
      title: '수면 장애',
      answer: [{ data: '' }],
    },
    {
      qNo: 27,
      title: '동반 질환',
      answer: [
        { data: '' },
        { label: '질환명', data: '' },
      ],
    },
    {
      qNo: 28,
      title: '합병증 여부',
      answer: [{ data: '' }, { label: '합병증 종류', data: [] }],
    },
    {
      qNo: 29,
      title: '저혈당 여부',
      answer: [{ data: '' }, { label: '3개월간 횟수', data: '' }],
    },
    {
      qNo: 30,
      title: '식사 습관',
      answer: [{ data: '' }, { label: '식사시 밥 양  (공기)', data: '' }],
    },
    {
      qNo: 31,
      title: '운동 여부 및 횟수',
      answer: [{ data: '' }, { label: ['운동 빈도', '운동 강도', '운동 종류', '운동 시간'], data: ['', '', '', ''] }, { label: '이유', data: '' }],
    },
    {
      qNo: 32,
      title: '이전 당뇨교육 여부',
      answer: [{ data: '' }, { label: '언제, 어디서', data: '' }],
    },
    {
      qNo: 33,
      title: '당뇨교육 참여 의사',
      answer: [{ data: '' }, { label: '이유', data: '' }],
    },
    {
      qNo: 34,
      title: '당화혈색소 수치',
      answer: [{ data: '' }, { label: ['결과', '시행시기'], data: ['', ''] }, { label: '이유', data: '' }],
    },
    {
      qNo: 35,
      title: '생활습관교육 참여의사',
      answer: [{ data: '' }, { label: '이유', data: '' }],
    },
    {
      qNo: 36,
      title: '거주지 근처 의료기관 안내',
      answer: [{ data: '' }, { label: '이유', data: '' }],
    },
    {
      qNo: 37,
      title: '추적 조사',
      answer: [{ data: '' }],
    },
    {
      qNo: 38,
      title: '방문 중인 의료 기관',
      answer: [{ data: '' }, { label: '병원명', data: '' }, { label: '이유', data: '' }],
    },
    {
      qNo: 39,
      title: '재검 여부',
      answer: [{ data: '' }, { label: '날짜', data: '' }, { label: '이유', data: '' }],
    },
    {
      qNo: 40,
      title: '합병증 평가 시행',
      answer: [{ data: '' }, { label: '이유', data: '' }],
    },
    {
      qNo: 41,
      title: '당뇨병 교육수행',
      answer: [{ data: '' }, { label: '이유', data: '' }],
    },
    {
      qNo: 42,
      title: '의료기관 방문 의사',
      answer: [{ data: '' }, { label: '이유', data: '' }],
    },
    {
      qNo: 43,
      title: '교육 자료 제공',
      answer: [{ data: '' }],
    },
    {
      qNo: 44,
      title: '당뇨 교육 참석 여부',
      answer: [{ data: '' }, { label: '참석 장소', data: '' }],
    },
    {
      qNo: 45,
      title: '기념품 지급여부',
      answer: [{ data: '' }, { label: '물품명', data: '' }],
    },
    {
      qNo: 98,
      title: '특이사항',
      answer: [{ data: '' }],
    },
    {
      qNo: 99,
      title: '메모',
      answer: [{ data: '' }],
    }
  );

  return { qData: qData };
}

import patientService from '@/services/patientService';
import _ from 'lodash';
export default {
  name: 'Survey',
  components: {
    // 여기서 동적으로 로딩한다
    Q1: () => import('@/components/surveys/Q1'),
    Q2: () => import('@/components/surveys/Q2'),
    Q3: () => import('@/components/surveys/Q3'),
    Q4: () => import('@/components/surveys/Q4'),
    Q5: () => import('@/components/surveys/Q5'),
    Q6: () => import('@/components/surveys/Q6'),
    Q7: () => import('@/components/surveys/Q7'),
    Q8: () => import('@/components/surveys/Q8'),
    Q9: () => import('@/components/surveys/Q9'),
    Q10: () => import('@/components/surveys/Q10'),
    Q11: () => import('@/components/surveys/Q11'),
    Q12: () => import('@/components/surveys/Q12'),
    Q13: () => import('@/components/surveys/Q13'),
    Q14: () => import('@/components/surveys/Q14'),
    Q15: () => import('@/components/surveys/Q15'),
    Q16: () => import('@/components/surveys/Q16'),
    Q17: () => import('@/components/surveys/Q17'),
    Q18: () => import('@/components/surveys/Q18'),
    Q19: () => import('@/components/surveys/Q19'),
    Q20: () => import('@/components/surveys/Q20'),
    Q21: () => import('@/components/surveys/Q21'),
    Q22: () => import('@/components/surveys/Q22'),
    Q23: () => import('@/components/surveys/Q23'),
    Q24: () => import('@/components/surveys/Q24'),
    Q25: () => import('@/components/surveys/Q25'),
    Q26: () => import('@/components/surveys/Q26'),
    Q27: () => import('@/components/surveys/Q27'),
    Q28: () => import('@/components/surveys/Q28'),
    Q29: () => import('@/components/surveys/Q29'),
    Q30: () => import('@/components/surveys/Q30'),
    Q31: () => import('@/components/surveys/Q31'),
    Q32: () => import('@/components/surveys/Q32'),
    Q33: () => import('@/components/surveys/Q33'),
    Q34: () => import('@/components/surveys/Q34'),
    Q35: () => import('@/components/surveys/Q35'),
    Q36: () => import('@/components/surveys/Q36'),
    Q37: () => import('@/components/surveys/Q37'),
    Q38: () => import('@/components/surveys/Q38'),
    Q39: () => import('@/components/surveys/Q39'),
    Q40: () => import('@/components/surveys/Q40'),
    Q41: () => import('@/components/surveys/Q41'),
    Q42: () => import('@/components/surveys/Q42'),
    Q43: () => import('@/components/surveys/Q43'),
    Q44: () => import('@/components/surveys/Q44'),
    Q45: () => import('@/components/surveys/Q45'),
    Q98: () => import('@/components/surveys/Q98'),
    Q99: () => import('@/components/surveys/Q99'),
  },
  props: {
    ptSeq: {
      type: Number,
    },
  },
  data() {
    return {
      qIndexs: {
        NEW: {
          v1: [1, 3, 9, 12, 16, 17, 18, 19, 20, 22, 23, 25, 26, 27, 21, 30, 31, 13, 35, 36, 42, 44, 43, 45, 98, 99],
          v2: [1, 4, 5, 6, 10, 11, 16, 17, 22, 24, 25, 26, 27, 30, 31, 36, 41, 43, 44, 45, 98, 99],
          v3: [1, 4, 7, 10, 11, 16, 17, 27, 22, 24, 25, 26, 30, 31, 36, 41, 43, 44, 45, 98, 99],
        },
        PRE: {
          v1: [1, 3, 9, 12, 13, 16, 17, 18, 19, 20, 21, 22, 23, 25, 26, 27, 30, 31,  35, 36, 42, 43, 44, 45, 98, 99],
          v2: [1, 4, 16, 17, 22, 24, 25, 26, 27, 30, 31, 38, 39, 41, 43, 44, 45, 98, 99],
        },
        OLD: {
          v1: [1, 3, 8, 9, 10, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 25, 26, 27, 28, 29, 30, 31, 32, 33, 43, 44, 45, 98, 99],
          v2: [1, 4, 5, 9, 10, 15, 16, 17, 22, 24, 25, 26, 27, 28, 29, 30, 31, 38, 40, 41, 43, 44, 45, 98, 99],
        },
      },
      // TODO : qIndex 정리 
      qDiags: [
        {
          ptDiagType: 'NEW',
          qSurvTypes: [
            {
              discription: '신규 v1',
              survType: 'v1',
              resultNo: 0,
              qIndex: [1, 3, 9, 12, 16, 17, 18, 19, 20, 22, 23, 25, 26, 27, 21, 30, 31, 13, 35, 36, 42, 44, 43, 45, 98, 99],
            },
            {
              discription: '신규 v2',
              survType: 'v2',
              resultNo: 1,
              qIndex: [1, 4, 5, 6, 10, 11, 16, 17, 22, 24, 25, 26, 27, 30, 31, 36, 41, 43, 44, 45, 98, 99],
            },
            {
              discription: '신규 v3',
              survType: 'v3',
              resultNo: 2,
              qIndex: [1, 4, 7, 10, 11, 16, 17, 27, 22, 24, 25, 26, 30, 31, 36, 41, 43, 44, 45, 98, 99],
            },
          ],
        },
        {
          ptDiagType: 'PRE',
          qSurvTypes: [
            {
              discription: '전단계 v1',
              survType: 'v1',
              resultNo: 3,
              qIndex: [1, 3, 9, 12, 13, 16, 17, 18, 19, 20, 21, 22, 23, 25, 26, 27, 30, 31, 35, 36, 42, 43, 44, 45, 98, 99],
            },
            {
              discription: '전단계 v2',
              survType: 'v2',
              resultNo: 4,
              qIndex: [1, 4, 16, 17, 22, 24, 25, 26, 27, 30, 31, 38, 39, 41, 43, 44, 45, 98, 99],
            },
          ],
        },
        {
          ptDiagType: 'OLD',
          qSurvTypes: [
            {
              discription: '기진단 v1',
              survType: 'v1',
              resultNo: 5,
              qIndex: [1, 3, 8, 9, 10, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 25, 26, 27, 28, 29, 30, 31, 32, 33, 43, 44, 45, 98, 99],
            },
            {
              discription: '기진단 v2',
              survType: 'v2',
              resultNo: 6,
              qIndex: [1, 4, 5, 9, 10, 15, 16, 17, 22, 24, 25, 26, 27, 28, 29, 30, 31, 38, 40, 41, 43, 44, 45, 98, 99],
            },
          ],
        },
      ],
      loaded: false,
      ptInfo: '',
      ptGender: '',
      ptHeight: '',
      ptWeight: '',
      ptSurvs: {
        first: '',
        last: '',
        all: '',
      },
      survTabs: [],
      curTab: '',
      curSurvSeq: '',
      survType: '',
      defaultResult: [],
      result: [
        // 신규 v1
        {},
        // 신규 v2
        {},
        // 신규 v3
        {},
        // 전단계 v1
        {},
        // 전단계 v2
        {},
        // 기진단 v1
        {},
        // 기진단 v2
        {},
      ],
      ptListEnum: {
        NEW: 1,
        PRE: 2,
        OLD: 3,
      },
      isSave: false, // 더블클릭 저장 방지
      originSurv: '',
      defaultSurv: ''
    };
  },
  methods: {
    // 환자 정보 불러오기
    getPtInfo() {
      patientService
        .getPatientSeq(this.ptSeq)
        .then((response) => {
          // 환자 정보
          this.ptInfo = response;
          this.ptGender = response.ptGender
          if (this.ptInfo.ptDiagCtgry === 'CONC') {
            this.survTabs = ['1차 문진', '2차 문진', '3차 문진', '4차 문진', '5차 문진', '6차 문진', '7차 문진', '8차 문진'];
          } else if (this.ptInfo.ptDiagCtgry === 'CTRL') {
            this.survTabs = ['1차 문진', '2차 문진', '3차 문진', '4차 문진'];
          } else return;

          // 설문 정보
          if (response.survs && response.survs.length > 0) {
            this.ptSurvs.all = response.survs;
            this.ptSurvs.first = response.survs[0];
            this.ptSurvs.last = response.survs[response.survs.length - 1];
            this.calWeight();
          }

          // 현재 탭
          if (response.survs.length === this.survTabs.length) {
            this.curTab = this.survTabs.length - 1;
          } else {
            this.curTab = response.survs.length;
          }

          this.survType = this.getSurvType(this.curTab, this.ptInfo.ptDiagType);
          this.defaultResult.qData = createSurveyForm().qData.filter((q) => {
            if (this.qIndexs[this.ptInfo.ptDiagType][this.survType].includes(q.qNo)) {
              return q;
            }
          });

          // 기존 설문 넣기
          this.originSurv = JSON.parse(JSON.stringify(response.survs));
          if(response.survs.length < this.survTabs.length) {
            this.defaultSurv = JSON.parse(JSON.stringify(this.defaultResult))
          } 

          // 처음 진입시 설문 불러오기
          this.getSurv(this.ptInfo.ptDiagType);
        })
        .catch((err) => {
          console.error('Error : ', err);
        });
    },
    // 설문 타입 설정
    getSurvType(curTab, diagType) {
      let survType;
      switch (diagType) {
        case 'NEW':
          if (curTab === 0) {
            survType = 'v1';
            break;
          } else if (curTab === 1) {
            survType = 'v2';
            break;
          } else {
            survType = 'v3';
            break;
          }
        case 'PRE':
          if (curTab === 0) {
            survType = 'v1';
            break;
          } else {
            survType = 'v2';
            break;
          }
        case 'OLD':
          if (curTab === 0) {
            survType = 'v1';
            break;
          } else {
            survType = 'v2';
            break;
          }
      }

      return survType;
    },
    getResultNum(survType, diagType) {
      var diag = this.qDiags.filter(function (qDiag) {
        return qDiag.ptDiagType == diagType;
      });

      var filterdSurvType = diag[0].qSurvTypes.filter(function (qSurvType) {
        return qSurvType.survType == survType;
      });
      return filterdSurvType[0].resultNo;
    },
    // 설문 불러오기
    getSurv(survCase) {
      this.survType = this.getSurvType(this.curTab, survCase);
      let resultNum = this.getResultNum(this.survType, survCase);

      if (this.ptSurvs.all.length > this.curTab) {
        this.result[resultNum] = Object.assign({}, this.ptSurvs.all[this.curTab].survCont);
        this.curSurvSeq = this.ptSurvs.all[this.curTab].survSeq;
      } else {
        this.result[resultNum] = Object.assign({}, this.defaultResult);
      }
    },
    // 탭 클릭
    clickTab(survCase, survTime) {
      if (this.ptSurvs.all.length >= survTime) {
        this.curTab = survTime;

        this.getSurv(survCase);
      } else return;
    },
    // 날짜 형식 (YYYY-MM-DD hh:mm:ss)
    formatDate(date) {
      let d = new Date(date);
      let formattedDate =
        d.getFullYear() + '-' + (d.getMonth() + 1) + '-' + d.getDate() + ' ' + d.getHours() + ':' + d.getMinutes() + ':' + d.getSeconds();
      return formattedDate;
    },
    // 표준체중 계산
    calWeight() {
      for (const value of Object.entries(this.ptSurvs.first.survCont.qData)) {
        if(value[1].title === '신체 계측') {
          // 키
          this.ptHeight = value[1].answer[1].data / 100;
        }
      }
      // 1차 설문에 성별/키 답변이 모두 있어야 실행
      if (this.ptSurvs.first && this.ptGender && this.ptHeight) {
        if (this.ptGender === '남') {
          this.ptWeight = (Math.pow(this.ptHeight, 2) * 22).toFixed(1);
        } else {
          this.ptWeight = (Math.pow(this.ptHeight, 2) * 21).toFixed(1);
        }
      } else return;
    },

    // 문진 저장(수정)
    save(survType) {

      // 설문 타입
      let resultNum = this.getResultNum(survType, this.ptInfo.ptDiagType);

      // 등록일 설정
      var regex= /[^0-9]/g; // 숫자 제외
      let regDt = '';
      let answerDt = _.find(this.result[resultNum].qData, {'title': '등록방법 및 등록일'}).answer[1].data;

      // 날짜 형식 맞지 않을 때 (YYYYMMDD)
      if((answerDt.length > 0 && answerDt.length !== 8) || (answerDt.length > 0 && regex.test(answerDt))){
        alert('등록일 문항에 올바른 날짜를 입력해 주세요. ex. 20210101');
        return;
      } 
      // 등록일 입력 안했을 때
      else if (!answerDt) {
        regDt = new Date().toISOString(); // 현재 시각
      }
      // 등록일 입력 O, 날짜 형식으로 바꾸기
      else {
        let d = new Date(answerDt.slice(0,4)+'-'+answerDt.slice(4,6)+'-'+answerDt.slice(6,8))
        if(isNaN(d) == false) {
          regDt = d.toISOString();
        } else { // 올바른 날짜가 아닐 경우 (Ex. 20201035)
          alert('등록일 문항에 올바른 날짜를 입력해 주세요. ex. 20210101');
          return;
        }
      }

      // 저장 클릭 감지
      this.isSave = true;

      // ** 저장하기 ** //
      if (this.isSave === true && this.ptSurvs.all.length === this.curTab) {
        // Body
        let body = {
          survTime: this.curTab,
          survDt: regDt,
          survCont: { qData: this.result[resultNum].qData },
        };
        
        // POST
        patientService
          .saveSurvey(this.ptSeq, body)
          .then(() => {
            this.$router.push(`/manage/case${this.ptListEnum[this.ptInfo.ptDiagType]}`);
          })
          .catch((err) => {
            alert('문진 저장에 실패했습니다. 잠시 후에 다시 시도해 주세요.');
            console.log(err);
          });
      }

      // ** 수정하기 ** //
      else if (this.isSave === true && this.ptSurvs.all.length > this.curTab) {

        // Body
        let body = {
          survTime: this.curTab,
          survDt: regDt,
          survCont: { qData: this.result[resultNum].qData },
        };

        // PUT
        patientService
          .updateSurvey({ param1: this.ptSeq, param2: this.curSurvSeq }, body)
          .then(() => {
            this.$router.push(`/manage/case${this.ptListEnum[this.ptInfo.ptDiagType]}`);
          })
          .catch((err) => {
            alert('문진 저장에 실패했습니다. 잠시 후에 다시 시도해 주세요.');
            console.log(err);
          });
      }
    },

    getDynamicComponent(no) {
      return 'Q' + no;
    },
  },
  created() {
    this.defaultResult = createSurveyForm(); // 새 문진에서 v-model에 바인딩이 안되는 이슈 해결
    this.getPtInfo();
  },
  beforeRouteLeave(to, from, next) {
    // 대상자 정보가 없을때 뒤로가기 막지 않음
    if(!this.ptInfo) {
      next();
      return;
    }
    // 이전 설문과 현재 설문 넣음
    let origin;
    let current = this.result[this.getResultNum(this.survType, this.ptInfo.ptDiagType)].qData;
    if(this.curTab < this.ptSurvs.all.length) { // 이전 차수 설문
      origin = this.originSurv[this.curTab].survCont.qData
    } else { // 신규 설문
      origin = this.defaultSurv.qData;
    }
    // 저장 버튼 눌렀을 때
    if (this.isSave) {
      next();
      this.isSave = false;
    } 
    // 기존 설문(origin)과 현재 설문(current) 일치여부 확인 및 조건부 이동
    else {
      if(JSON.stringify(current) === JSON.stringify(origin)) {
        next();
      } else {
        const msg = window.confirm(`${this.ptInfo.ptNm}님의 문진 정보가 저장되지 않았습니다. 입력한 정보를 저장하지 않고 이동하시겠습니까?`);
        if (msg) {
          next(); 
        } else {
          next(false);
        }
      }
    }

  },
};
</script>