import http from "@/services/httpClient";

const END_POINT = "/users"

class UserService {

    // 사용자 전체 조회
    getUsersAll() {
        return http.get(END_POINT).then(response => response.data.data ? response.data.data.userResponses : []);
    }
    // 사용자 개별 조회
    // getUser(body) {
    //     return http.get(END_POINT, { params: body }).then(response => response.data.data ? response.data.data.userResponses : []);
    // }
    // 사용자 개별 등록
    registerUser(body) {
        return http.post(END_POINT, body)
    }
    // 사용자 정보 수정
    updateUser(params, body) {
        return http.put(`${END_POINT}/${params}`, body);
    }
    // 사용자 비밀번호 변경
    updatePw(params, body) {
        return http.put(`${END_POINT}/chgPwd/${params}`, body);
    }
    // 사용자 삭제
    delUser(params) {
        return http.delete(`${END_POINT}/${params}`);
    }
}

export default new UserService()