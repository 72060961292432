<template>
  <div class="login">
    <div class="main-logo text-center">
      <img src="../assets/images/vincent-logo-2.svg" />
      <h1>당뇨 환자 관리 시스템</h1>
    </div>
    <form class="login-form" @submit.prevent="doLogin"> 
      <b-form-input v-model="email" type="email" placeholder="이메일 아이디" />
      <b-form-input v-model="password" type="password" placeholder="비밀번호" />
      <div class="error-msg" v-if="errorMsg">{{ errorMsg }}</div>
      <b-btn type="submit" block v-bind:class="{ valid: !$v.$invalid }">
        로그인
      </b-btn>
    </form>
    <div class="login-info">
      <p>로그인시 필요한 정보는 관리자에게 문의하세요.</p>
    </div>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { email } from 'vuelidate/lib/validators';
import auth from "@/services/authService";

export default {
  name: "Login",
  data() {
    return {
      email: "",
      password: "",
      errorMsg: "",
      loginData: {
        isLogin: false,
        token: "",
        userNm: ""
      }
    };
  },
  validations: {
    email: {
      required,
      email
    },
    password: {
      required
    }
  },
  methods: {
    // 로그인
    doLogin() {
      if (this.$v.$invalid) {
        this.errorMsg = "아이디와 비밀번호를 정확히 입력해 주세요.";
        return;
      }
      sessionStorage.setItem("loginData", "{}");
      auth
        .post({ userId: this.email, userPwd: this.password })
        .then(response => {
          this.loginData = {
            isLogin: true,
            token: response.data.token.accessToken,
            userId: response.data.user.userId,
            userNm: response.data.user.userNm,
            userRole: response.data.user.roles[0].roleNm,
          };
          sessionStorage.setItem("loginData", JSON.stringify(this.loginData));
          this.$router.push("/home");
        })
        .catch(err => {
          // this.errorMsg = err.message;
          if (err.status === 401) {
            this.errorMsg = err.message;
            // this.errorMsg = "아이디와 비밀번호가 일치하지 않습니다.";
          } else {
            this.errorMsg = "일시적인 오류입니다. 잠시 후 다시 시도해 주세요.";
            console.error("Error : ", err);
          }
        });
    }
  }
};
</script>
