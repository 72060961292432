<!-- 다음 주소 검색 API -->
<script src="https://t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js"></script>

<template>
  <b-modal id="patient_modal">

    <!-- Modal Header -->
    <template #modal-header>
      <div class="modal-title">
        <div v-if="!modifyMode && !selected">대상자 등록</div>
        <div v-if="!modifyMode && selected">대상자 정보</div>
        <div v-if="modifyMode">대상자 수정</div>
      </div>
    </template>

    <!-- Modal Body -->
    <b-form-group label-cols="3" label="성명">
      <div class="form-info" v-if="!modifyMode && selected">{{ this.selected.ptNm }}</div>
      <div v-if="modifyMode || !selected">
        <b-form-input type="text" v-model="patientForm.ptNm"></b-form-input>
        <p class="error-msg mt-2" v-if="$v.patientForm.ptNm.$invalid">성명을 입력해 주세요. (최소 2자)</p>
      </div>
    </b-form-group>

    <b-form-group label-cols="3" label="성별">
      <div class="form-info" v-if="!modifyMode && selected">
        <span v-if="this.selected.ptGender === 'M'">남</span>
        <span v-if="this.selected.ptGender === 'W'">여</span>
      </div>
      <div v-if="modifyMode || !selected">
        <b-row class="sex">
          <b-col>
            <input
              class="form-check-input"
              v-model="patientForm.ptGender"
              type="radio"
              id="ptGender_male"
              value="M"
            />
            <label class="form-check-label btn btn-block" for="ptGender_male">
              남
            </label>
          </b-col>
          <b-col>
            <input
              class="form-check-input"
              v-model="patientForm.ptGender"
              type="radio"
              id="ptGender_female"
              value="W"
            />
            <label class="form-check-label btn btn-block" for="ptGender_female">
              여
            </label>
          </b-col>
        </b-row>
        <p class="error-msg mt-2" v-if="$v.patientForm.ptGender.$invalid">성별을 선택해 주세요.</p>
      </div>
    </b-form-group>

    <b-form-group label-cols="3" label="생년월일">
      <div class="form-info" v-if="!modifyMode && selected">{{ this.selected.ptBirth }}</div>
      <div v-if="modifyMode || !selected">
        <b-form-input
          type="text"
          v-model="patientForm.ptBirth"
          placeholder="YYYYMMDD"
          trim
        />
        <p class="error-msg mt-2" v-if="$v.patientForm.ptBirth.$invalid"
          >생년월일을 숫자 8자리로 입력해 주세요 (ex.19900101)</p
        >
      </div>
    </b-form-group>

    <b-form-group label-cols="3" label="연락처">
      <div class="form-info" v-if="!modifyMode && selected">{{ this.selected.ptPhone }}</div>
      <div v-if="modifyMode || !selected">      
        <b-form-input
          type="text"
          v-model="patientForm.ptPhone"
          placeholder="숫자만 입력"
        />
      </div>
      <p class="error-msg mt-2" v-if="$v.patientForm.ptPhone.$invalid">
        숫자만 입력해 주세요.
      </p>
    </b-form-group>

    <b-form-group label-cols="3" label="주소">
      <div class="form-info" v-if="!modifyMode && selected">
        <p class="mb-2"><b class="mr-2">우)</b>{{ this.selected.ptZipCode }}</p>
        <p>{{ this.selected.ptAddr }} {{ this.selected.ptDtlAddr }}</p>
      </div>
      <div v-if="modifyMode || !selected">
        <b-row class="postcode mb-2">
          <b-col cols="7" class="pl-0 pr-2">
            <input
              class="form-control"
              type="number"
              v-model="patientForm.ptZipCode"
              placeholder="우편번호"
              disabled
            />
          </b-col>
          <b-col cols="5" class="p-0">
            <b-button block variant="secondary" @click="searchAddress()">주소 검색</b-button>
          </b-col>
        </b-row>
        <b-form-input
          type="text"
          class="mb-2"
          v-model="patientForm.ptAddr"
          placeholder="기본주소"
          disabled
        ></b-form-input>
        <b-form-input
          type="text"
          v-model="patientForm.ptDtlAddr"
          ref="ptDtlAddr"
          placeholder="상세주소"
        ></b-form-input>
      </div>
    </b-form-group>

    <b-row>
      <b-col cols="3"></b-col>
      <b-col cols="9">
        <div class="error-msg" v-if="errorMsg">{{ errorMsg }}</div>
      </b-col>
    </b-row>

    <!-- Modal Footer -->
    <template #modal-footer>
      <div class="w-100 d-flex justify-content-between">
        <div>
          <b-button v-if="!modifyMode && selected" variant="outline-danger" @click="delInfo(selected.ptSeq)">삭제</b-button>
        </div>
        <div>
          <b-button variant="outline-secondary mr-2" @click="dismiss()">취소</b-button>

          <b-button v-if="!modifyMode && selected" variant="secondary" @click="modify()">수정</b-button>
          <b-button v-if="modifyMode || !selected" variant="secondary" @click="submit(selected.ptSeq)">저장</b-button>
        </div>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { required, minLength, maxLength, integer } from "vuelidate/lib/validators";
import patientService from "@/services/patientService";

export default {
  name: "PatientModal",
  props: ['selected'],
  data() {
    return {
      modifyMode: false,
      patientForm: {
        ptNm: "",
        ptGender: "",
        ptBirth: "",
        ptPhone: "",
        ptZipCode: "",
        ptAddr: "",
        ptDtlAddr: ""
      },
      errorMsg: ""
    } 
  },
  validations: {
    patientForm: {  
      ptNm: {
        required,
        minLength: minLength(2)
      },
      ptGender: {
        required
      },
      ptBirth: {
        integer,
        minLength: minLength(8),
        maxLength: maxLength(8)
      },
      ptPhone: {
        integer
      }
    }
  },
  methods: {

    // 오늘 날짜 계산
    calToday() {
      let today = this.$moment(new Date()).format('YYYYMMDD')
      return today*1;
    },

    // 날짜 형식 체크
    checkDateInvalid(date) {
      let d = new Date(date.slice(0,4)+'-'+date.slice(4,6)+'-'+date.slice(6,8))
      date = date*1;
      if(date < 19000101 || date > this.calToday() || isNaN(d) == true) {
        return true;
      } else {
        return false;
      }
    },

    // 주소 검색
    searchAddress() {
      let vue_this = this;
      new daum.Postcode({
        oncomplete: function(data) {
          var roadAddr = data.roadAddress; // 도로명 주소 변수
          var extraRoadAddr = ''; // 참고 항목 변수

          // 법정동명
          if (data.bname !== '' && /[동|로|가]$/g.test(data.bname)) {
            extraRoadAddr += data.bname;
          }
          // 건물명이 있고, 공동주택일 경우 추가
          if (data.buildingName !== '' && data.apartment === 'Y') {
            extraRoadAddr += (extraRoadAddr !== '' ? ', ' + data.buildingName : data.buildingName);
          }
          // 참고항목
          if (extraRoadAddr !== '') {
            extraRoadAddr = ' (' + extraRoadAddr + ')';
          }

          // 우편번호와 주소 정보를 필드에 넣음
          var resultZip = data.zonecode;
          var resultAddr = roadAddr + extraRoadAddr;
          vue_this.setAddr(resultZip, resultAddr);

          // 커서를 상세주소 필드로 이동
          vue_this.ptDtlAddr = '';
          vue_this.$refs.ptDtlAddr.focus();
        }
      }).open();
    },

    // 주소 검색 결과 patientForm에 입력
    setAddr(zip, addr) {
      this.patientForm.ptZipCode = zip
      this.patientForm.ptAddr = addr
    },

    modify() {
      this.modifyMode = true;
      this.patientForm = Object.assign({}, this.selected);
    },

    // 저장
    submit(ptSeq) {

      if (this.$v.$invalid) {
        this.errorMsg = "형식에 맞게 입력해 주세요.";
        return;
      } else if (this.patientForm.ptBirth && this.checkDateInvalid(this.patientForm.ptBirth)) {
        this.errorMsg = "올바른 생년월일을 입력해 주세요. (ex. 20210101)";
        return;
      } else {
        this.errorMsg = "";

        if(ptSeq) {
          // 대상자 수정
          patientService.updatePatient(ptSeq, this.patientForm)
          .then(() => {
            this.dismiss();
          })
          .catch((err) => {
            this.errorMsg = "일시적인 오류가 발생했습니다. 잠시후 다시 시도해 주세요.";
            console.error("Error : ", err);
          });
        } else { 
          // 대상자 등록 (신규) 
          patientService.registerPatient(this.patientForm)
          .then(() => {
            this.dismiss();
          })
          .catch((err) => {
            this.errorMsg = "일시적인 오류가 발생했습니다. 잠시후 다시 시도해 주세요.";
            console.error("Error : ", err);
          });
        }
      }
    },

    // 삭제
    delInfo(ptSeq) {
      this.$bvModal.msgBoxConfirm('정보 삭제시 복구가 불가능합니다. 삭제하시겠습니까?', {
        okVariant: 'danger',
        okTitle: '삭제',
        cancelTitle: '취소',
        cancelVariant: 'outline-secondary'
      })
      .then(value => {
        if(value == true) {
          patientService.delPatient(ptSeq)
          .then( () => {
            this.$bvModal.hide("patient_modal");
            this.modifyMode = false;
          })
          .catch( err => {
            alert('대상자 삭제에 실패했습니다. 잠시 후에 다시 시도해 주세요.');
            console.log(err)
          });
        }
      })
    },

    // 취소
    dismiss() {
      this.$bvModal.hide('patient_modal');
      if(this.modifyMode) {
        this.modifyMode = false;
      }
      this.patientForm = {
        ptNm: "",
        ptGender: "",
        ptBirth: "",
        ptPhone: "",
        ptZipCode: "",
        ptAddr: "",
        ptDtlAddr: ""
      }
      this.errorMsg = "";
    }
  }
};
</script>