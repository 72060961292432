import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../views/Login.vue";
import Home from "../views/Home.vue";
import ManageAll from "../views/ManageAll.vue";
import ManageCase1 from "../views/ManageCase1.vue";
import ManageCase2 from "../views/ManageCase2.vue";
import ManageCase3 from "../views/ManageCase3.vue";
import Result from "../views/Result.vue";
import Survey from "../views/Survey.vue";
import User from "../views/User.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: { name: 'Login' }
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    // meta: { disallowAuthed: true } // 로그인 화면에서 뒤로가기 막음
  },
  {
    path: "/home",
    name: "Home",
    component: Home
  },
  {
    path: "/manage/all",
    name: "ManageAll",
    component: ManageAll
  },
  {
    path: "/manage/case1",
    name: "ManageCase1",
    component: ManageCase1
  },
  {
    path: "/manage/case2",
    name: "ManageCase2",
    component: ManageCase2
  },
  {
    path: "/manage/case3",
    name: "ManageCase3",
    component: ManageCase3,
  },
  {
    path: "/manage/:case/survey",
    name: "Survey",
    component: Survey,
    props: true,
  },
  {
    path: "/result",
    name: "Result",
    component: Result
  },
  {
    path: "/user",
    name: "User",
    component: User
  }
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes
});

export default router;
