<template>
  <div class="manage">
    <!-- 검색 (전체 리스트) -->
    <section v-if="!$route.params.isContact">
      <h2 class="section-title">검색</h2>
      <b-form class="search-form row">
        <b-form-group class="col" label="성명">
          <b-form-input type="text" v-model="search_form.ptNm"></b-form-input>
        </b-form-group>
        <b-form-group class="col" label="성별">
          <b-form-select v-model="search_form.ptGender" :options="search_options.ptGender"></b-form-select>
        </b-form-group>
        <b-form-group class="col" label="휴대전화">
          <b-form-input type="text" v-model="search_form.ptPhone" placeholder="숫자만 입력 가능"></b-form-input>
        </b-form-group>
        <b-form-group class="col" label="분류">
          <b-form-select v-model="search_form.ptDiagCtgry" :options="search_options.ptDiagCtgry"></b-form-select>
        </b-form-group>
        <div class="d-flex align-items-end">
          <b-button variant="secondary" type="button" @click="search()"><b-icon class="mr-1" icon="search" scale="0.7"></b-icon>검색</b-button>
        </div>
      </b-form>
    </section>

    <!-- 검색 (2주내 문진 대상자 리스트) -->
    <section v-if="$route.params.isContact">
      <h2 class="section-title">검색</h2>
      <b-form class="search-form row">
        <b-form-group class="col" label="성명">
          <b-form-input type="text" v-model="search_form.ptNm"></b-form-input>
        </b-form-group>
        <b-form-group class="col" label="성별">
          <b-form-select v-model="search_form.ptGender" :options="search_options.ptGender"></b-form-select>
        </b-form-group>
        <b-form-group class="col" label="휴대전화">
          <b-form-input type="text" v-model="search_form.ptPhone" placeholder="숫자만 입력 가능"></b-form-input>
        </b-form-group>
        <b-form-group class="col" label="분류">
          <b-form-select v-model="search_form.ptDiagCtgry" :options="search_options.ptDiagCtgry"></b-form-select>
        </b-form-group>
        <div class="d-flex align-items-end">
          <b-button variant="secondary" type="button" @click="searchInContact()"
            ><b-icon class="mr-1" icon="search" scale="0.7"></b-icon>검색</b-button
          >
        </div>
      </b-form>
    </section>

    <!-- 리스트 -->
    <section>
      <h2 class="section-title">대상자 정보</h2>

      <!-- Tabel Header -->
      <div class="table-header">
        <div class="d-flex justify-content-end">
          <b-form-select class="select-view" v-model="view.view_selected" :options="view.view_options" @change="changeViews"></b-form-select>
        </div>
      </div>

      <!-- Table -->
      <b-table-simple>
        <!-- Table Head -->
        <b-thead head-variant="light">
          <b-tr>
            <b-th class="th-sm" v-for="field in manage_all_data.field" :key="field.key">
              {{ field.label }}
              <span class="label-info" v-if="field.label_info"><br />{{ field.label_info }}</span>
            </b-th>
          </b-tr>
        </b-thead>

        <!-- Table Body -->
        <b-tbody>
          <b-tr v-for="(data, index) in manage_all_data.items" :key="index">
            <!-- 순서 -->
            <b-td>{{ data.index }}</b-td>
            <!-- 등록번호 -->
            <b-td>{{ data.ptDiagCode ? data.ptDiagCode : '-' }}</b-td>
            <!-- 성명 -->
            <b-td>
              <span class="inline-btn" @click="openPatientInfo(data)">
                {{ data.ptNm }}
              </span>
            </b-td>
            <!-- 성별 -->
            <b-td>{{ data.ptGender === 'M' ? '남' : '여' }}</b-td>
            <!-- 생년월일 -->
            <b-td>{{ data.ptBirth }}</b-td>
            <!-- 연령 -->
            <b-td>{{ data.ptBirth ? getAge(data.ptBirth) : '' }}</b-td>
            <!-- 휴대전화 -->
            <b-td>{{ data.ptPhone }}</b-td>
            <!-- 동의서 -->
            <b-td>
              <b-button-group size="md">
                <b-button
                  v-for="(item, item_idx) in state_options.agree_options"
                  :key="item_idx"
                  @click="putState('agree', data.ptSeq, item.value)"
                  :pressed="item.value === data.ptAgmtYn"
                  variant="outline-secondary"
                >
                  {{ item.text }}
                </b-button>
              </b-button-group>
            </b-td>
            <!-- 분류 -->
            <b-td>
              <b-button-group size="md" :class="data.survs.length > 0 ? 'disabled' : ''">
                <b-button
                  v-for="(item, item_idx) in state_options.group_options"
                  :key="item_idx"
                  @click="putState('group', data.ptSeq, item.value)"
                  :pressed="item.value === data.ptDiagCtgry"
                  variant="outline-secondary"
                >
                  {{ item.text }}
                </b-button>
              </b-button-group>
            </b-td>
            <!-- 상태 -->
            <b-td class="multiline">
              <p>{{ getSurvTime(data) }}</p>
              <p>{{ getSurvDate(data) }}</p>
            </b-td>
            <!-- 문진 예정일 -->
            <!-- #cadbf0 -->
            <b-td class="next-survey multiline">
              <p>{{ getSurvNextTime(data) }}</p>
              <p v-if="data.nextSurvDate">{{ data.nextSurvDate.slice(0, 10) }}</p>
            </b-td>
            <!-- 문진 -->
            <b-td>
              <b-button variant="outline-success" @click="doSurvey(data.ptSeq)">문진하기</b-button>
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>

      <!-- Table Footer -->
      <div class="table-footer d-flex justify-content-end">
        <!-- Pagination-->
        <b-button-group size="md">
          <b-button variant="outline-secondary" @click="prevPage" :disabled="page === 1"><b-icon icon="chevron-left"></b-icon></b-button>
          <b-button variant="outline-secondary" @click="nextPage" :disabled="page === totalPage"><b-icon icon="chevron-right"></b-icon></b-button>
        </b-button-group>
      </div>
    </section>

    <!-- ************* Modals ************* -->

    <!-- 대상자 등록/수정/정보 -->
    <PatientModal :selected="this.selected_patient" />
  </div>
</template>

<script>
import patientService from '@/services/patientService';
import PatientModal from '@/components/PatientModal.vue';
import _ from 'lodash';

export default {
  name: 'PatientList',
  props: ['case'],
  data() {
    return {
      page: 1,
      totalPage: Number,

      // 검색 폼
      search_form: {
        ptNm: '',
        ptGender: '',
        ptPhone: '',
        ptDiagType: this.case,
        ptDiagCtgry: '',
      },

      // 검색 결과 컨테이너
      search_result: '',

      // 검색 셀렉트 옵션
      search_options: {
        ptGender: [
          { value: '', text: '전체' },
          { value: 'M', text: '남' },
          { value: 'W', text: '여' },
        ],
        ptAgmtYn: [
          { value: '', text: '전체' },
          { value: 'Y', text: '동의' },
          { value: 'N', text: '미동의' },
        ],
        ptDiagType: [
          { value: '', text: '전체' },
          { value: 'NONE', text: '미선택' },
          { value: 'NEW', text: '신규진단' },
          { value: 'PRE', text: '전단계' },
          { value: 'OLD', text: '기진단' },
        ],
        ptDiagCtgry: [
          { value: '', text: '전체' },
          { value: 'CONC', text: '집중군' },
          { value: 'CTRL', text: '대조군' },
        ],
      },

      // N개씩 보기
      view: {
        view_selected: localStorage.getItem('list_view') ? `${localStorage.getItem('list_view')}` : '10',
        view_options: [
          { value: 10, text: '10개씩 보기' },
          { value: 30, text: '30개씩 보기' },
          { value: 50, text: '50개씩 보기' },
          { value: 100, text: '100개씩 보기' },
          { value: 'all', text: '전체 보기' },
        ],
      },

      // 상태 버튼 (테이블 안)
      state_options: {
        agree_options: [
          { value: 'Y', text: 'O' },
          { value: 'N', text: 'X' },
        ],
        group_options: [
          { value: 'CONC', text: '집중군' },
          { value: 'CTRL', text: '대조군' },
        ],
      },

      // 대상자 테이블 데이터
      manage_all_data: {
        field: [
          { key: 'index', label: 'No' },
          { key: 'ptId', label: '등록번호' },
          { key: 'ptNm', label: '성명' },
          { key: 'ptGender', label: '성별' },
          { key: 'ptBirth', label: '생년월일' },
          { key: 'age', label: '연령', label_info: '(만 나이)' },
          { key: 'ptPhone', label: '휴대전화' },
          { key: 'ptAgmtYn', label: '동의서' },
          { key: 'ptDiagCtgry', label: '분류' },
          { key: 'survState', label: '상태' },
          { key: 'nextSurvDate', label: '문진 예정일' },
          { key: 'doSurv', label: '문진' },
        ],
        items: Object,
      },

      // 대상자 정보 모달 > 선택된 대상자
      selected_patient: '',

      // 설문 상태
      curSurvState: {
        date: '',
        time: '',
      },
    };
  },
  methods: {
    // 대상자 전체 정보 불러오기
    getAllData(page) {
      patientService
        .getPatient({ ptDiagType: this.case })
        .then((response) => {
          let index = 1;
          let data = [];
          response.forEach((e) => {
            if (e.ptDiagCtgry === 'CONC' || e.ptDiagCtgry === 'CTRL') {
              e.index = index;
              data.push(e);
              index++;
            }
          });

          this.viewPage(data, page);
        })
        .catch((err) => {
          console.error('Error : ', err);
        });
    },

    // 설문 날짜 넣기
    getSurvDate(data) {
      if (data.survs.length > 0) {
        return data.survs[data.survs.length - 1].survDt.slice(0, 10);
      } else return '-';
    },

    // 설문 현재 차수 넣기
    getSurvTime(data) {
      // 진행한 설문이 있는 상태
      if (data.survs.length > 0) {
        let survTime = data.survs[data.survs.length - 1].survTime;
        return survTime + 1 + '차';
      }
      // 진행한 설문이 없는 상태 (1차 설문 진행 필요)
      else if (data.survs.length == 0) {
        return '1차';
      } else return;
    },

    // 설문 다음 차수 넣기
    getSurvNextTime(data) {
      // 설문 최대값
      let MAX_SURV_TIME = 1;
      if (data.ptDiagCtgry === 'CONC') {
        MAX_SURV_TIME = 8;
      } else if (data.ptDiagCtgry === 'CTRL') {
        MAX_SURV_TIME = 4;
      } else return;

      // 진행한 설문이 있는 상태
      if (data.survs.length > 0) {
        let survTime = data.survs[data.survs.length - 1].survTime;
        if (survTime >= 0 && survTime + 1 < MAX_SURV_TIME) {
          return survTime + 2 + '차';
        } else if (survTime + 1 === MAX_SURV_TIME) {
          return '문진완료';
        }
      }
      // 진행한 설문이 없는 상태 (1차 설문 진행 필요)
      else if (data.survs.length == 0) {
        return '1차';
      } else return;
    },

    // 전체 대상자 검색
    search() {
      let body = JSON.parse(JSON.stringify(this.search_form));
      patientService
        .getPatient(body)
        .then((response) => {
          let index = 1;
          _.forEach(response, (pt) => {
            pt.index = index;
            index++;
          });

          this.viewPage(response, 1);
          this.search_result = response;
        })
        .catch((err) => {
          console.error('Error : ', err);
        });
    },

    // 2주내 문진 대상자 검색
    searchInContact() {
      let searchResult = [];
      let index = 1;
      this.$route.params.target.forEach((e) => {
        if (
          (e.ptNm.includes(this.search_form.ptNm) || this.search_form.ptNm === '') &&
          (e.ptGender === this.search_form.ptGender || this.search_form.ptGender === '') &&
          (e.ptPhone.includes(this.search_form.ptPhone) || this.search_form.ptPhone === '') &&
          (e.ptDiagCtgry === this.search_form.ptDiagCtgry || this.search_form.ptDiagCtgry === '')
        ) {
          e.index = index;
          index++;
          searchResult.push(e);
        }
      });
      this.viewPage(searchResult, 1);
    },

    // 상태 변경 버튼 (동의서, 진단 구분, 분류)
    putState(data, ptSeq, value) {
      let body;

      if (data === 'agree') {
        // 동의서
        body = { ptAgmtYn: value };
        patientService
          .updateStateAgree(ptSeq, body)
          .then(() => {
            this.getAllData(this.page);
          })
          .catch((err) => {
            alert('대상자 상태 수정에 실패했습니다. 잠시 후에 다시 시도해 주세요.');
            console.log(err);
          });
      } else {
        if (data === 'case') {
          // 진단 구분
          body = { ptDiagType: value };
        } else if (data === 'group') {
          // 분류
          body = { ptDiagCtgry: value };
        } else return;
        patientService
          .updateStatePatient(ptSeq, body)
          .then(() => {
            this.getAllData(this.page);
          })
          .catch((err) => {
            alert('대상자 상태 수정에 실패했습니다. 잠시 후에 다시 시도해 주세요.');
            console.log(err);
          });
      }
    },

    // 대상자 나이 불러오기
    getAge(ptBirth) {
      return patientService.calAge(ptBirth);
    },

    // 대상자 정보 모달
    openPatientInfo(ptNm) {
      this.selected_patient = ptNm;
      this.$bvModal.show('patient_modal');
    },

    // 리스트 출력 (페이지 분할, 정렬)
    viewPage(items, page) {
      let views = this.view.view_selected;
      let priorItems = []; // 등록번호가 없는 묶음
      let noPriorItems = []; // 등록번호가 있는 묶음
      items.forEach((item) => {
        if (!item.ptDiagCode) {
          priorItems.push(item);
          priorItems = _.orderBy(priorItems, 'regDt', 'desc');
        } else {
          noPriorItems.push(item);
          noPriorItems = _.orderBy(noPriorItems, 'regDt', 'desc');
        }
      });
      let allItems = priorItems.concat(noPriorItems);

      if (views !== 'all') {
        this.totalPage = Math.ceil(items.length / views);
        this.manage_all_data.items = allItems.slice(views * (page - 1), views * page);
      } else {
        this.totalPage = 1;
        this.manage_all_data.items = allItems;
      }
    },

    // 문진하기
    doSurvey(ptSeq) {
      let caseNm;
      if (this.case === 'new') caseNm = 'case1';
      else if (this.case === 'pre') caseNm = 'case2';
      else if (this.case === 'old') caseNm = 'case3';
      else return;

      this.$router.push({
        name: 'Survey',
        params: {
          case: caseNm,
          ptSeq: Number(ptSeq),
        },
      });
    },

    // 페이지 전환
    prevPage() {
      this.page--;
      if (this.search_result.length > 0) {
        this.viewPage(this.search_result, this.page);
      } else this.getAllData(this.page);
    },
    nextPage() {
      this.page++;
      if (this.search_result.length > 0) {
        this.viewPage(this.search_result, this.page);
      } else this.getAllData(this.page);
    },

    // 게시물수 변경
    changeViews() {
      localStorage.setItem('list_view', this.view.view_selected);
      this.getAllData(1);
    },
  },
  created() {
    // 2주내 문진 대상자
    if (this.$route.params.isContact) {
      let target = JSON.parse(JSON.stringify(this.$route.params.target));
      let index = 1;

      _.forEach(target, (pt) => {
        pt.index = index;
        index++;
      });

      this.viewPage(target, 1);
    }
    // 대상자 전체
    else {
      this.getAllData(this.page);
    }
  },
  mounted() {
    this.$root.$on('bv::modal::hide', (bvModalEvt, modalId) => {
      if (modalId === 'patient_modal') this.getAllData(this.page);
    });
  },
  beforeDestroy() {
    this.$root.$off('bv::modal::hide');
  },
  components: {
    PatientModal,
  },
};
</script>