<template>
  <div class="home">
    <section class="content-title">
      <h1>대시보드</h1>
    </section>

    <!-- 연락 대상자 인원 -->
    <section>
      <div class="today-date">
        <b-row>
          <b-col>
            <h2>{{ date() }}</h2>
          </b-col>
          <b-col>
            <p><b>등록된 전체 인원 수</b>{{ allPatientsNum }}명</p>
          </b-col>
        </b-row>
      </div>
      <h2 class="section-title">
        연락 대상자 인원
        <span>*1차 문진 시작일 기준. 각 문진 이주일 전 대상자 명단</span>
      </h2>
      <b-row class="card-wrapper" v-if="contactList">
        <!-- 문진이 있는 사람만 뜸 -->
        <b-col v-for="(diagType, i) in contactList" :key="i">
          <div class="card case1">
            <div class="card-header">
              <h3>{{ diagTypeEnum[i] }}</h3>
              <p> {{ (diagType.CONC ? diagType.CONC.length : 0) + (diagType.CTRL ? diagType.CTRL.length : 0) }}명 </p>
            </div>
            <div class="card-body">
              <b-row v-for="(diagCtgry, j) in diagType" :key="j" @click="goDetail(i, diagCtgry)">
                <b-col class="left">{{ diagCtgryEnum[j] }}</b-col>
                <b-col class="ight"> {{ diagCtgry.length ? diagCtgry.length : 0 }}명 </b-col>
              </b-row>
            </div>
          </div>
        </b-col>
      </b-row>
    </section>

    <!-- 진행 상태 정보 -->
    <section v-if="progressList">
      <h2 class="section-title">진행 상태 정보</h2>
      <b-table-simple>
        <b-thead head-variant="light">
          <b-tr>
            <b-th v-for="field in tableFields" :key="field.key">{{ field.label }}</b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr v-for="(data, index) in tableData" :key="index">
            <b-th rowspan="2" v-if="data.case && index % 2 === 0">
              {{ diagTypeEnum[data.case] }}
            </b-th>
            <b-td>
              {{ diagCtgryEnum[data.group] }}
            </b-td>
            <b-td v-for="(num, j, timeIndex) in data.didSurvey" :key="j" v-bind:class="{ disabled: !num }">
              {{
                num && data.case && data.group && progressList[data.case] && progressList[data.case][data.group]
                  ? progressList[data.case][data.group][timeIndex]
                    ? progressList[data.case][data.group][timeIndex][0]
                      ? progressList[data.case][data.group][timeIndex][0]['count(last_time)']
                      : 0
                    : 0
                  : ''
              }}
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </section>
  </div>
</template>

<script>
import dashboardService from '@/services/dashboardService';

export default {
  name: 'Home',
  data() {
    return {
      diagTypeEnum: {
        NEW: '신규 진단',
        PRE: '전단계',
        OLD: '기진단',
      },
      diagCtgryEnum: {
        CONC: '집중군',
        CTRL: '대조군',
      },
      goDetailEnum: {
        NEW: 1,
        PRE: 2,
        OLD: 3,
      },
      contactList: null,
      progressList: null,
      allPatientsNum: 0,
      tableData: [
        {
          case: 'NEW',
          group: 'CONC',
          didSurvey: {
            basic: '0',
            time1: '0',
            time2: '0',
            time3: '0',
            time4: '0',
            time5: '0',
            time6: '0',
            time7: '0',
          },
        },
        {
          case: 'NEW',
          group: 'CTRL',
          didSurvey: {
            basic: '0',
            time1: '0',
            time2: '0',
            time3: '0',
            time4: '',
            time5: '',
            time6: '',
            time7: '',
          },
        },
        {
          case: 'PRE',
          group: 'CONC',
          didSurvey: {
            basic: '0',
            time1: '0',
            time2: '0',
            time3: '0',
            time4: '0',
            time5: '0',
            time6: '0',
            time7: '0',
          },
        },
        {
          case: 'PRE',
          group: 'CTRL',
          didSurvey: {
            basic: '0',
            time1: '0',
            time2: '0',
            time3: '0',
            time4: '',
            time5: '',
            time6: '',
            time7: '',
          },
        },
        {
          case: 'OLD',
          group: 'CONC',
          didSurvey: {
            basic: '0',
            time1: '0',
            time2: '0',
            time3: '0',
            time4: '0',
            time5: '0',
            time6: '0',
            time7: '0',
          },
        },
        {
          case: 'OLD',
          group: 'CTRL',
          didSurvey: {
            basic: '0',
            time1: '0',
            time2: '0',
            time3: '0',
            time4: '',
            time5: '',
            time6: '',
            time7: '',
          },
        },
      ],
      tableFields: [
        { key: 'case', label: '항목' },
        { key: 'group', label: '구분' },
        { key: 'basicSurvey', label: '1차' },
        { key: 'time1', label: '2차' },
        { key: 'time2', label: '3차' },
        { key: 'time3', label: '4차' },
        { key: 'time4', label: '5차' },
        { key: 'time5', label: '6차' },
        { key: 'time6', label: '7차' },
        { key: 'time7', label: '8차' },
      ],
    };
  },
  methods: {
    // 오늘 날짜 불러오기
    date() {
      var currentDate = new Date();
      return currentDate.getFullYear() + '년 ' + (currentDate.getMonth() + 1) + '월 ' + currentDate.getDate() + '일';
    },

    // 2주내 문진 대상자 목록
    goDetail(diagType, diagCtgry) {
      this.$router.push({
        name: `ManageCase${this.goDetailEnum[diagType]}`,
        params: {
          isContact: true,
          target: diagCtgry,
        },
      });
    },

    // 대시보드 데이터 불러오기
    getAllData() {
      dashboardService
        .getDashboard()
        .then((response) => {
          this.allPatientsNum = response.total;
          this.contactList = response.contactList;
          this.progressList = response.progressList;

          console.log(this.contactList)
        })
        .catch((err) => {
          console.error('Error : ', err);
        });
    },
  },
  created() {
    this.date();
    this.getAllData();
  },
};
</script>
