<template>
  <b-modal id="upload_modal">
    <!-- Modal Header -->
    <template #modal-header>
      <div class="modal-title">대상자 일괄 등록</div>
    </template>

    <div class="upload-modal-wrapper">
      <!-- Modal Body -->
      <b-form-file
        v-model="uploadFile"
        :state="Boolean(uploadFile)"
        placeholder="파일을 첨부해 주세요."
        drop-placeholder="파일을 첨부해 주세요."
        browse-text="파일 찾기"
      ></b-form-file>
      <div class="mt-3">선택된 파일: {{ uploadFile ? uploadFile.name : '' }}</div>
      <div class="mt-3 text-danger">* 엑셀은 지정된 양식을 사용해야 합니다.<br>* 생년월일은 8자리 숫자이며, 휴대폰 번호는 텍스트 형식으로 입력되어야 합니다.</div>
    </div>

    <!-- Modal Footer -->
    <template #modal-footer>
      <div class="w-100 d-flex justify-content-end">
        <b-button variant="outline-secondary mr-2" @click="dismiss()">취소</b-button>
        <b-button variant="secondary" @click="upload()">등록</b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import patientService from '@/services/patientService';
import _ from 'lodash';
export default {
  name: 'UploadModal',
  data() {
    return {
      uploadFile: null,
    };
  },
  methods: {
    // 저장
    upload() {
      let formData = new FormData();
      formData.append('file', this.uploadFile);
      patientService
        .registerMultiPatients(formData)
        .then((res) => {
          if (res.data.failed.length > 0) {
            let uploadErr = _.map(res.data.failed, 'index');
            alert('대상자 일괄 등록에 실패했습니다. 엑셀 파일의 '+uploadErr.join()+'줄을 확인해 주세요.')
          } else {
            this.dismiss();
            alert('대상자 일괄 등록에 성공했습니다.');
          }
        })
        .catch((err) => {
          alert('대상자 일괄 등록에 실패했습니다. 다시 시도해 주세요.');
          console.error('Error : ', err);
        });
    },
    // 취소
    dismiss() {
      this.uploadFile = null;
      this.$bvModal.hide('upload_modal');
    },
  },
};
</script>