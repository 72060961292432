<template>
  <div class="manage">
    <!-- Content Title -->
    <section class="content-title">
      <h1>당뇨병 기진단<span class="ml-2" v-if="$route.params.isContact">: 2주내 문진 대상자</span></h1>
    </section>

    <!-- 리스트 -->
    <PatientList case="old"></PatientList>

  </div>
</template>

<script>
import PatientList from '@/components/PatientList.vue'
export default {
  data() {
    return {
    }
  },
  components: {
    PatientList,
  },
}
</script>