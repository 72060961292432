import http from "@/services/httpClient";

const END_POINT = "/patients"

class PatientService {

    // 대상자 전체 조회
    getPatientsAll() {
        return http.get(END_POINT).then(response => response.data.data ? response.data.data.ptResponses : []);
    }
    // 대상자 개별 조회 (Search)
    getPatient(body) {
        return http.get(END_POINT, { params: body }).then(response => response.data.data ? response.data.data.ptResponses : []);
    }
    // 대상자 개별 조회 (ptSeq)
    getPatientSeq(params) {
        return http.get(`${END_POINT}/${params}`).then(response => response.data.data ? response.data.data.ptResponse : []);
    }
    // 대상자 개별 등록
    registerPatient(body) {
        return http.post(END_POINT, body)
    }
    // 대상자 일괄 등록
    registerMultiPatients(body) {
        return http.post(`${END_POINT}/multi`, body)
    }
    // 대상자 정보 수정
    updatePatient(params, body) {
        return http.put(`${END_POINT}/${params}`, body);
    }
    // 대상자 상태 정보 수정 (동의)
    updateStateAgree(params, body) {
        return http.put(`${END_POINT}/${params}/updateAgmt`, body);
    }
    // 대상자 상태 정보 수정 (진단, 분류)
    updateStatePatient(params, body) {
        return http.put(`${END_POINT}/diag/${params}`, body);
    }
    // 대상자 삭제
    delPatient(params) {
        return http.delete(`${END_POINT}/${params}`);
    }
    // 문진 저장
    saveSurvey(params, body) {
        return http.post(`${END_POINT}/${params}/surveys`, body)
    }
    // 문진 수정
    updateSurvey(params, body) {
        return http.put(`${END_POINT}/${params.param1}/surveys/${params.param2}`, body)
    }
    // 문진 결과 다운로드
    getResult() {
        return http.get(`${END_POINT}/list/result/excel`, {
            responseType: 'blob',
        }).then(response => response.data ? response : []);
    }

    // 연령 계산
    calAge(ptBirth) {
        // 생일 날짜 값 받아오고 숫자로 변환
        let birthYear =  ptBirth.replaceAll('-','').slice(0,4)*1
        let birthMonth =  ptBirth.replaceAll('-','').slice(4,6)-1;
        let birthDay =  ptBirth.replaceAll('-','').slice(6,8)*1;
        const today = new Date();
        const birthDate = new Date(birthYear, birthMonth, birthDay);

        // 만 나이 계산
        let age = today.getFullYear() - birthDate.getFullYear();
        const m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        return age;
    }
}

export default new PatientService()