import http from "@/services/httpClient";

const END_POINT = "/auth"

class AuthService {

    get(params) {
        return http.get(END_POINT, params)
    }
    post(body) {
        return http.post(END_POINT, body)
    }
}

export default new AuthService()