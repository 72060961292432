<template>
  <div class="user">
    <!-- Content Title -->
    <section class="content-title">
      <h1>사용자 관리</h1>
    </section>

    <!-- 리스트 -->
    <section>
      <h2 class="section-title d-flex justify-content-between">
        <div>사용자 목록<span>총 {{ user_all_data.items.length }} 건</span></div>
        <b-button 
        v-if="curUser.userRole === '최고관리자'"
        variant="outline-primary" @click="regitsterUser()">사용자 등록</b-button>
      </h2>

      <!-- Table -->
      <b-table-simple>

        <!-- Table Head -->
        <b-thead head-variant="light">
          <b-tr>
            <b-th v-for="field in user_all_data.field" :key="field.key">
              {{ field.label }}
            </b-th>
          </b-tr>
        </b-thead>

        <!-- Table Body -->
        <b-tbody>
          <b-tr v-for="(data, index) in user_all_data.items" :key="index">
            <b-td>
              {{ data.userId }}
            </b-td>
            <b-td>
              {{ data.roles[0].roleNm }}
            </b-td>
            <b-td>
              {{ data.userNm }}
            </b-td>
            <b-td>
              {{ data.userTel }}
            </b-td>
            <b-td>
              {{ data.userPhone }}
            </b-td>
            <b-td>
              <b-button 
              v-if="curUser.userRole === '최고관리자' || curUser.userId === data.userId"
              variant="outline-secondary" @click="updateUser(data)">수정하기</b-button>
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
      
      <!-- Table Footer -->
      <div class="table-footer d-flex justify-content-end">
        <!-- Pagination-->
        <b-button-group size="md">
          <b-button
            variant="outline-secondary"
            @click="prevPage"
            :disabled="page === 1"
            ><b-icon icon="chevron-left"></b-icon
          ></b-button>
          <b-button
            variant="outline-secondary"
            @click="nextPage"
            :disabled="page === totalPage"
            ><b-icon icon="chevron-right"></b-icon
          ></b-button>
        </b-button-group>
      </div>
    </section>

    <!-- ************* Modals ************* -->

    <!-- 사용자 등록 -->
    <UserModal v-model="selected_user" :isNew="isNew"/>

  </div>
</template>


<script>
import userService from '@/services/userService';
import UserModal from '@/components/UserModal.vue';
import _ from 'lodash';

export default {
  data() {
    return {
      page: 1,
      totalPage: Number,
      curUser: JSON.parse(sessionStorage.getItem('loginData')),

      // 사용자 테이블 데이터
      user_all_data: {
        field: [
          { key: 'userId', label: '이메일 ID' },
          { key: 'userRole', label: '권한' },
          { key: 'userNm', label: '이름' },
          { key: 'userTel', label: '유선전화' },
          { key: 'userPhone', label: '휴대전화' },
          { key: 'updateUser', label: '수정' },
        ],
        items: Object,
      },

      // 사용자 정보 모달 > 선택된 사용자
      selected_user: {
        userId: '',
        userNm: '',
        userPwd: '',
        userPhone: '',
        userTel: '',
        roleIds: ['ROLE_USER'],
      },

      isNew : true
    }
  },
  methods: {

    // 사용자 전체 정보 불러오기
    getAllData(page) {
      userService
        .getUsersAll()
        .then((response) => {
          this.viewPage(response, page);
        })
        .catch((err) => {
          console.error('Error : ', err)
        })
    },

    // 리스트 출력 (페이지 분할, 정렬)
    viewPage(items, page) {
      let views = 20;
      this.totalPage = Math.ceil(items.length / views)
      this.user_all_data.items = _.orderBy(items, 'regDt', 'desc').slice(
        views * (page - 1),
        views * page
      )
    },
    
    // 페이지 전환
    prevPage() {
      this.page--
      this.getAllData(this.page)
    },
    nextPage() {
      this.page++
      this.getAllData(this.page)
    },

    // 사용자 등록 모달
    regitsterUser() {
      this.selected_user = {
        userId: '',
        userNm: '',
        userPwd: '',
        userPhone: '',
        userTel: '',
        roleIds: ['ROLE_USER'],
      }
      this.isNew = true;
      this.$bvModal.show('user_register_modal')
    },

    // 사용자 수정 모달
    updateUser(userData) {
      this.selected_user = Object.assign({}, userData);
      this.isNew = false;
      this.$bvModal.show('user_register_modal');
    }
  },
  created() {
    this.getAllData(this.page)
  },
  mounted() {
    this.$root.$on('bv::modal::hide', (bvModalEvt, modalId) => {
      if(modalId ==='user_register_modal' || modalId === 'user_pw_modal') this.getAllData(this.page);
    });
  },
  beforeDestroy(){
    this.$root.$off('bv::modal::hide');
  },
  components: {
    UserModal,
  }
}
</script>