<template>
  <div id="app">
    <SideNav v-if="$route.name !== 'Login'" />
    <div id="content" class="content" v-bind:class="{ isSide : $route.name !== 'Login'}">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import SideNav from "@/components/SideNav.vue";

export default {
  data() {
    return {
    };
  },
  mounted() {
    if(this.checkLogin() === false && this.$route.path!=="/login") {
      this.$router.push("/login")
    }
  },
  methods: {
    checkLogin() {
      if(sessionStorage.getItem('loginData')) {
        return true;
      } else {
        return false;
      }
    }
  },
  components: {
    SideNav
  }
};
</script>
