import http from "@/services/httpClient";

const END_POINT = "/dashboard"

class DashboardService {

    // 대시보드 데이터 조회
    getDashboard() {
        return http.get(`${END_POINT}/all`).then(response => response.data.data ? response.data.data.dashboard : []);
    }
}

export default new DashboardService()