<template>
  <div>
    <!-- 사용자 등록 / 수정 모달 -->
    <b-modal id="user_register_modal">
      <!-- Modal Header -->
      <template #modal-header>
        <div class="modal-title">{{
          isNew ? '사용자 등록' : '사용자 수정'
        }}</div>
      </template>
      <!-- Modal Body -->
      <b-form-group label-cols="3" label="이메일 ID">
        <!-- 사용자 등록시 -->
        <div v-if="isNew">
          <b-form-input type="text" v-model="userForm.userId"></b-form-input>
          <p class="error-msg mt-2" v-if="$v.userForm.userId.$invalid">
            이메일 ID를 입력해 주세요.
          </p>
        </div>
        <!-- 사용자 수정시 -->
        <div v-if="!isNew" class="h-100 d-flex align-items-center">
          <p class="mb-0">{{ userForm.userId }}</p>
        </div>
      </b-form-group>

      <b-form-group label-cols="3" label="비밀번호">
        <!-- 사용자 등록시 -->
        <div v-if="isNew">
          <b-form-input
            type="password"
            v-if="isNew"
            v-model="userForm.userPwd"
          ></b-form-input>
          <p class="error-msg mt-2" v-if="$v.userForm.userPwd.$invalid">
            비밀번호는 8~20자로 영문, 숫자, 특수문자('₩' 제외)를 모두 포함하여 구성해 주세요.
          </p>
        </div>
        <!-- 사용자 수정시 -->
        <div v-if="!isNew">
          <b-button variant="outline-secondary" @click="openPwModal()"
            >비밀번호 변경</b-button
          >
        </div>
      </b-form-group>

      <b-form-group label-cols="3" label="이름">
        <b-form-input type="text" v-model="userForm.userNm"></b-form-input>
        <p class="error-msg mt-2" v-if="$v.userForm.userNm.$invalid">
          성명을 입력해 주세요. (최소 2자)
        </p>
      </b-form-group>

      <b-form-group label-cols="3" label="유선전화">
        <b-form-input
          type="text"
          v-model="userForm.userTel"
          placeholder="숫자만 입력하세요"
        />
        <p class="error-msg mt-2" v-if="$v.userForm.userTel.$invalid">
          숫자만 입력하세요.
        </p>
      </b-form-group>

      <b-form-group label-cols="3" label="휴대전화">
        <b-form-input
          type="text"
          v-model="userForm.userPhone"
          placeholder="숫자만 입력하세요"
        />
        <p class="error-msg mt-2" v-if="$v.userForm.userPhone.$invalid">
          숫자만 입력하세요.
        </p>
      </b-form-group>

      <b-row>
        <b-col cols="3"></b-col>
        <b-col cols="9">
          <div class="error-msg" v-if="errorMsg">{{ errorMsg }}</div>
        </b-col>
      </b-row>

      <!-- Modal Footer -->
      <template #modal-footer>
        <div
          class="w-100 d-flex"
          v-bind:class="
            !isNew ? 'justify-content-between' : 'justify-content-end'
          "
        >
          <b-button
            v-if="!isNew"
            variant="outline-danger"
            @click="delUser(userForm.userId)"
            >삭제</b-button
          >
          <div>
            <b-button variant="outline-secondary mr-2" @click="dismiss()"
              >취소</b-button
            >
            <b-button variant="secondary" @click="submit()">저장</b-button>
          </div>
        </div>
      </template>
    </b-modal>

    <!-- 비밀번호 변경 모달 -->
    <b-modal id="user_pw_modal">
      <!-- Modal Header -->
      <template #modal-header>
        <div class="modal-title">비밀번호 변경</div>
      </template>

      <!-- Modal Body -->
      <b-form-group label-cols="4" label="현재 비밀번호">
        <b-form-input type="password" v-model="pwForm.oldPwd"></b-form-input>
        <p class="error-msg mt-2" v-if="$v.pwForm.oldPwd.$invalid">현재 비밀번호를 입력해 주세요.</p>
      </b-form-group>
      <b-form-group label-cols="4" label="새 비밀번호">
        <b-form-input type="password" v-model="pwForm.newPwd"></b-form-input>
        <p class="error-msg mt-2" v-if="$v.pwForm.newPwd.$invalid">새 비밀번호를 8~20자로 영문, 숫자, 특수문자('₩' 제외)를 모두 포함하여 구성해 주세요.</p>
      </b-form-group>
      <b-form-group label-cols="4" label="새 비밀번호 확인">
        <b-form-input type="password" v-model="confirmPwd"></b-form-input>
        <p class="error-msg mt-2" v-if="$v.confirmPwd.$invalid">새 비밀번호를 다시 한 번 입력해 주세요.</p>
      </b-form-group>

      <!-- Modal Footer -->
      <template #modal-footer>
        <div class="w-100 d-flex justify-content-end">
          <b-button
            variant="outline-secondary mr-2"
            @click="dismissChangePw()"
            >취소</b-button
          >
          <b-button variant="secondary" @click="changePw()"
            >비밀번호 변경</b-button
          >
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import userService from '@/services/userService'
import { required, minLength, email, integer, helpers } from 'vuelidate/lib/validators'
import _ from 'lodash'
const pwValid = helpers.regex('pwValid', /^(?=.*[A-Za-z])(?=.*\d)(?=.*[\W])[A-Za-z\d\W][^₩]{8,20}$/)

export default {
  name: 'UserModal',
  model: {
    prop: 'userForm',
  },
  props: ['userForm', 'isNew'],
  data() {
    return {
      errorMsg: '',
      pwForm: {
        oldPwd: '',
        newPwd: '',
      },
      confirmPwd: '',
    }
  },
  validations: {
    userForm: {
      userId: {
        required,
        email,
      },
      userPwd: {
        required,
        pwValid
      },
      userNm: {
        required,
        minLength: minLength(2)
      },
      userTel: {
        integer,
      },
      userPhone: {
        integer,
      },
    },
    pwForm: {
      oldPwd: {
        required
      },
      newPwd: {
        required,
        pwValid
      },
    },
    confirmPwd: {
      required
    }
  },
  methods: {

    // 비밀번호 변경
    openPwModal() {
      this.$bvModal.hide('user_register_modal')
      this.$bvModal.show('user_pw_modal')
    },
    changePw() {
      if(this.pwForm.newPwd === this.confirmPwd) {
        let body = JSON.parse(JSON.stringify(this.pwForm));
        userService
        .updatePw(this.userForm.userId, body)
        .then(() => {
          this.$bvModal.hide('user_pw_modal');
          this.pwForm.oldPwd = ''
          this.pwForm.newPwd = ''
          this.confirmPwd = ''
        })
        .catch((err) => {
          if(err.status === 401) {
            alert('현재 비밀번호가 일치하지 않습니다.')
          } 
          this.errorMsg =
            '일시적인 오류가 발생했습니다. 잠시후 다시 시도해 주세요.'
          console.error('Error : ', err)
        })
      } else {
        alert('변경하고자하는 비밀번호가 일치하지 않습니다.');
      }
    },

    // 사용자 등록 취소
    dismiss() {
      this.$bvModal.hide('user_register_modal');
    },

    // 비밀번호 변경 취소
    dismissChangePw() {
      this.$bvModal.hide('user_pw_modal');
      this.pwForm.oldPwd = ''
      this.pwForm.newPwd = ''
      this.confirmPwd = ''
    },

    // 저장
    submit() {
      if (this.isNew) {
        if (this.$v.userForm.$invalid) {
          this.errorMsg = '형식에 맞게 입력해 주세요.'
          return
        } else {
          // 사용자 등록
          userService
          .registerUser(this.userForm)
          .then(() => {
            this.$bvModal.hide('user_register_modal')
          })
          .catch((err) => {
            this.errorMsg =
              '일시적인 오류가 발생했습니다. 잠시후 다시 시도해 주세요.'
            console.error('Error : ', err)
          })
        }
      } else {
        this.userForm.roleIds = _.map(this.userForm.roles, 'roleId')

        // 사용자 수정
        userService
        .updateUser(this.userForm.userId, this.userForm)
        .then(() => {
          this.$bvModal.hide('user_register_modal')
        })
        .catch((err) => {
          this.errorMsg =
            '일시적인 오류가 발생했습니다. 잠시후 다시 시도해 주세요.'
          console.error('Error : ', err)
        })
      }
    },

    // 삭제
    delUser(userId) {
      this.$bvModal
        .msgBoxConfirm('정말 삭제하시겠습니까?', {
          okVariant: 'danger',
          okTitle: '삭제',
          cancelTitle: '취소',
          cancelVariant: 'outline-secondary',
        })
        .then((value) => {
          if (value == true) {
            userService
              .delUser(userId)
              .then(() => {
                this.$bvModal.hide('user_register_modal')
                alert('사용자가 삭제되었습니다.')
              })
              .catch((err) => {
                alert(
                  '사용자 삭제에 실패했습니다. 잠시 후에 다시 시도해 주세요.'
                )
                console.log(err)
              })
          }
        })
    },
  },
}
</script>