import axios from 'axios';

 const BASE_URL = process.env.VUE_APP_BASE_URL;
 
// axios 객체
const http = axios.create({
  baseURL: BASE_URL,
  timeout: 8000,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*" 
  }
});

/**
 * 에러 인터셉터
 * @param { Object } error 에러
 */
const errorInterceptor = error => {
  return Promise.reject(handleError(error));
}

/**
 * 응답 인터셉터
 * @param { Object } response 응답객체
 */
const responseInterceptor = response => {
  return response;
}

/**
 * 통신 에러처리 기본 핸들러
 * @param error 에러
 */
function handleError(error) {
  let errorBody = error.response && error.response.data.error || {};
  let errResponse;

  if (Object.keys(errorBody).length === 0) {
    errResponse = error;

  }
  else {
    errResponse = {
      code: errorBody?.code,
      status: errorBody?.statusCode,
      statusMessage: errorBody?.statusMessage,
      message: errorBody?.message
    }
  }

  return errResponse;
}

const getAuthToken = () => sessionStorage.getItem('loginData')

const authInterceptor = (config) => {
  let loginData = JSON.parse(getAuthToken());
  config.headers.Authorization = `Bearer ${loginData.token}`;
  return config;
}
http.interceptors.request.use(authInterceptor);
http.interceptors.response.use(responseInterceptor, errorInterceptor);

export default http;
