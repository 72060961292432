<template>
  <div class="sidenav">
    <div class="main-logo text-center">
      <router-link to="/home">
        <img src="../assets/images/vincent-logo.svg" />
        <h1>당뇨 환자 관리 시스템</h1>
      </router-link>
    </div>
    <b-nav>
      <b-nav-item to="/home">
        <img class="nav-icon" src="../assets/images/icon-dashboard.svg" />
        대시보드
      </b-nav-item>
      <div class="sub-nav-wrapper">
        <b-nav-item to="/manage/all">
          <img class="nav-icon" src="../assets/images/icon-target.svg" />
          대상자 관리
        </b-nav-item>
        <div class="sub-nav">
          <b-nav-item to="/manage/all">
            대상자 전체 목록
          </b-nav-item>
          <b-nav-item to="/manage/case1">
            당뇨병 신규 진단 (A)
          </b-nav-item>
          <b-nav-item to="/manage/case2">
            당뇨병 전단계 (B)
          </b-nav-item>
          <b-nav-item to="/manage/case3">
            당뇨병 기진단 (C)
          </b-nav-item>
        </div>
      </div>
      <b-nav-item to="/result">
        <img class="nav-icon" src="../assets/images/icon-result.svg" />
        결과 관리
      </b-nav-item>
      <b-nav-item to="/user">
        <img class="nav-icon" src="../assets/images/icon-settings.svg" />
        사용자 관리
      </b-nav-item>
    </b-nav>
    <div class="account">
      <p><b-icon icon="person-circle"></b-icon>{{ userName }} {{ userRole }}님</p>
      <b-nav-item to="/login" class="logout-btn" @click="logout()">로그아웃</b-nav-item>
    </div>
  </div>
</template>

<script>
export default {
  name: "SideNav",
  data() {
    return {
      userName: JSON.parse(sessionStorage.getItem('loginData')).userNm,
      userRole: JSON.parse(sessionStorage.getItem('loginData')).userRole
    }
  },
  methods: {
    logout() {
      sessionStorage.clear()
    }
  }
};
</script>